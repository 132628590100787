<template>
  <div>
    <h3 style="margin-top: 15px" class="custom-header main-color">
      {{ $t("Choose Payment Method") }}
    </h3>

    <v-row class="center_horizontal">
      <v-col cols="12" offset-md="2" sm="12" md="4">
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-radio @click="GoToPayment('mada')" label="Mada"> </v-radio>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-img
              class="mada_img"
              src="../../assets/mada_icon.png"
              width="120"
              height="120"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-row>
          <v-col cols="12" sm="12" md="5">
            <v-radio @click="GoToPayment('master')" label="Visa/Master">
            </v-radio>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-img
              class="visa_img"
              src="../../assets/mastercard-and-visa-icon.png"
              width="100"
              height="100"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <FooterNotLogin v-if="!isLogin()"></FooterNotLogin>
  </div>
</template>
<script>
import FooterNotLogin from "../../components/FooterNotLoginComponent";
export default {
  data() {
    return {};
  },
  components: {
    FooterNotLogin
  },
  methods: {
    isLogin() {
      return localStorage.login && localStorage.login == "true" ? true : false;
    },
    GoToPayment(brand) {
      if (brand == "mada") {
        this.$router.push({
          path:
            `/HyperPayMada/` +
            this.$router.currentRoute.params.code +
            "?isInvoice=" +
            this.$route.query.isInvoice +
            "&invoiceId=" +
            this.$route.query.invoiceId
        });
      } else {
        this.$router.push({
          path:
            `/HyperPay/` +
            this.$router.currentRoute.params.code +
            "?isInvoice=" +
            this.$route.query.isInvoice +
            "&invoiceId=" +
            this.$route.query.invoiceId
        });
      }
    }
  }
};
</script>
<style scoped>
.center_horizontal {
  text-align: center;
  vertical-align: middle;
  margin-top: 20%;
}
.mada_img {
  margin-top: -40px;
}
.visa_img {
  margin-top: -20px;
}

@media (max-width: 800px) {
  .mada_img {
    margin-top: -20px;
  }
  .visa_img {
    margin-top: 0;
  }
}
</style>
